import { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";
import { CWidgetStatsF, CLink } from "@coreui/react";
import { FaBullhorn } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import logoSIBUCSC from "../../assets/images/home/navbar/SIBUCSC.png";
import Button from "react-bootstrap/Button";

const Preprints = () => {
  const [showPub, setShowPub] = useState(false);
  const handleCloseDep = () => setShowPub(false);

  function handleShowPub() {
    setShowPub(true);
  }

  return (
    <>
      <Container>
        <Row className="row-lib pt-2 mt-5">
          <Col>
            <div className="invDev mt-1">
              <div className="inner">
                <p className="font-bold text-justify">¿Qué es un Preprint?</p>
                <p>
                  Prepublicación o preimpresión (en inglés preprint) es una
                  versión de un artículo académico o científico que precede a
                  la revisión formal entre pares y publicación en una revista
                  académica o científica.
                </p>
                <p>
                  La preimpresión puede estar disponible, a menudo como una
                  versión no tipográfica disponible gratuitamente, antes y/o
                  después de que se publique un artículo en una revista
                  académica
                </p>
                <p className="text-justify mt-4">
                  Recibe DOI, enlace a ORCID, verificación de plagio,
                  oportunidad de recibir becas y premios, promoción de
                  investigadores jóvenes, crédito temprano
                </p>
                <p className="font-bold text-justify">
                  IMPORTANTE: Los preprints reciben un DOI diferente de las
                  versiones finales publicadas de un artículo. (Wikipedia)
                </p>
                <p className="title">Ventajas de las preimpresiones</p>
                <ul className="features">
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Diseminación rápida de los resultados
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Contribuye al libre flujo de información
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Aumenta las posibilidades de Retroalimentación y
                      comentarios tempranos
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Contribuye al libre flujo de información
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Aumenta el número de citas, las posibilidades de
                      colaboraciones académicas
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Reducir las publicaciones fraudulentas
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Publica resultados negativos y controversias, buen lugar
                      para hipótesis y detección temprana de mala conducta
                      científica.
                    </span>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path
                          fill="currentColor"
                          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Recibe DOI, enlace a ORCID, verificación de plagio,
                      oportunidad de recibir becas y premios, promoción de
                      investigadores jóvenes, crédito temprano
                    </span>
                  </li>
                </ul>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3">
            <CWidgetStatsF
              className="mb-3 font-bold"
              color="danger"
              footer={
                <CLink
                  className="font-weight-bold font-xs text-[#d12421]"
                  style={{ cursor: "pointer" }}
                  rel="noopener norefferer"
                  target="_blank"
                  onClick={() => handleShowPub()}
                >
                  Ver información
                </CLink>
              }
              icon={<FaBullhorn height={24} />}
              title="¿Deseas publicar un artículo en Preprint?"
            />
          </Col>
        </Row>
      </Container>
      <Modal show={showPub} size="xl" centered>
        <Modal.Header>
          <Modal.Title className="text-center">
            Pasos para publicar en plataformas Preprints
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="invDev mt-1">
            <ul className="features">
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>Autorización de todos los autores</span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Elección del servidor de preprint (Tomar en cuenta la
                  categoría temática, visibilidad, incluido en servicios de
                  indexación, recomendaciones de la agencia de financiación,
                  entre otros)
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Revisar las Políticas de la revista elegida para publicarlo
                  luego (Consultar SherpaRomeo y Transpose)
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Elección de las licencias (Creative Commons) (Si va a crear
                  patente luego consulte con la Oficina de transferencia
                  tecnológica de su institución)
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Diseño de la publicación y envío a la plataforma elegida
                </span>
              </li>
              <li>
                <span className="icon">
                  <svg
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path
                      fill="currentColor"
                      d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                    ></path>
                  </svg>
                </span>
                <span>
                  Luego de publicado en la plataforma de Preprints elegida,
                  difunda y/o comparta con sus colegas pares en redes sociales y
                  otras plataformas
                </span>
              </li>
            </ul>
            <div className="m-5">
              <p className="font-bold">
                Servidores de publicación de Preprints
              </p>
              <p>
                Son depósitos digitales en donde está permitido el acceso
                público a los manuscritos originales antes de que se lleve a
                cabo la revisión por pares.
              </p>
            </div>
            <div className="mt-3 ms-5">
              <p className="font-bold">
                Plataformas Preprints
              </p>
            </div>
            <div className="ms-3">
              <ul className="features inline ">
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    Para conocer más sobre preprints{" "}
                    <a
                      href="https://asapbio.org/preprint-servers"
                      className="enlace"
                      target="_blank"
                    >
                      https://asapbio.org/preprint-servers
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    ARXIV{" "}
                    <a
                      href="http://arxiv.org/"
                      className="enlace"
                      target="_blank"
                    >
                      http://arxiv.org/
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    BIORXIV.ORG{" "}
                    <a
                      href="http://biorxiv.org"
                      className="enlace"
                      target="_blank"
                    >
                      http://biorxiv.org
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    EARTHARXIV: Es un servidor de preprints dedicado a la
                    comunicación académica abierta. EarthArXiv publica artículos
                    de todos los subdominios de las ciencias de la Tierra y
                    dominios relacionados de las ciencias planetarias.{" "}
                    <a
                      href="https://eartharxiv.org/"
                      className="enlace"
                      target="_blank"
                    >
                      https://eartharxiv.org/
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    F1000-RESEARCH: Plataforma multidisciplinaria de publicación
                    de preprints{" "}
                    <a
                      href="http://f1000research.com/"
                      className="enlace"
                      target="_blank"
                    >
                      http://f1000research.com/
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    PREPRINTS.ORG: Plataforma multidisciplinaria de publicación
                    de preprints.{" "}
                    <a
                      href="https://preprints.org"
                      className="enlace"
                      target="_blank"
                    >
                      https://preprints.org
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    CHEMRXIV{" "}
                    <a
                      href="http://pubs.acs.org/meetingpreprints"
                      className="enlace"
                      target="_blank"
                    >
                      http://pubs.acs.org/meetingpreprints
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    PEERJ PREPRINTS{" "}
                    <a
                      href="http://peerj.com/archives-preprints"
                      className="enlace"
                      target="_blank"
                    >
                      http://peerj.com/archives-preprints
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    REPEC (Research Papers in Economics) es una iniciativa que
                    busca mejorar la difusión de la investigación en Economía y
                    áreas relacionadas. Queremos hacer que la investigación sea
                    más accesible tanto para los autores como para los lectores.{" "}
                    <a
                      href="http://repec.org/"
                      className="enlace"
                      target="_blank"
                    >
                      http://repec.org/
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    SOCARXIV: Plataforma de publicación de Preprints en Ciencias
                    Sociales y humanidades{" "}
                    <a
                      href="https://osf.io/preprints/socarxiv"
                      className="enlace"
                      target="_blank"
                    >
                      https://osf.io/preprints/socarxiv
                    </a>{" "}
                  </span>
                </li>
                <li>
                  <span className="icon">
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        fill="currentColor"
                        d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                      ></path>
                    </svg>
                  </span>
                  <span>
                    PSYARXIV PREPRINTS: Plataforma enfocada en publicar
                    preprints en el área de psicología.{" "}
                    <a
                      href="https://osf.io/preprints/psyarxiv/discover"
                      className="enlace"
                      target="_blank"
                    >
                      https://osf.io/preprints/psyarxiv/discover
                    </a>{" "}
                  </span>
                </li>
              </ul>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ float: "left" }}>
          <div className="w-100">
            <img src={logoSIBUCSC} className="h-10 bannerModal"></img>
            <Button variant="secondary float-right" onClick={handleCloseDep}>
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <br></br>
    </>
  );
};
export default Preprints;
