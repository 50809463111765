import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Plagio from "./Plagiarism";

const IAcademic = () => {
  const autoria = [
    {
      id: 1,
      item: "Falsificar autorías, omitiendo a algún autor.",
    },
    {
      id: 2,
      item: "Autorías injustificadas, realizando un reconocimiento indebido a autores afamados con menos mérito que otros autores no famosos (Efecto Mateo).",
    },
    {
      id: 3,
      item: "Añadir autores amigos, aunque no hayan participado en la investigación.",
    },
    {
      id: 4,
      item: "Por conveniencia, agradecimiento de favores, prestigio, coacciones, captar simpatía, etc.",
    },
  ];

  const citas = [
    {
      id: 1,
      item: "Omitir citas bibliográficas contrarias a la posición del autor. ",
    },
    {
      id: 2,
      item: "Plagio, ciberplagio y auto-plagio.",
    },
    {
      id: 3,
      item: "No Identificar claramente lo que recoge de otros autores, y No da crédito incluso a las buenas ideas que ha recibido verbalmente de otros (Miranda Montecinos, 2013)",
    },
    {
      id: 4,
      item: "Incorrección de citas.",
    },
    {
      id: 5,
      item: "En la lista de publicaciones, No identificar claramente las reimpresiones o nuevas versiones de una publicación previa. Si la nueva es substancialmente igual a la anterior, la agrega dentro del mismo número, para evitar que una publicación en duplicado cuente como si fueran dos trabajos diversos.",
    },
  ];

  const datos = [
    {
      id: 1,
      item: "No guardar los datos primarios de una investigación.",
    },
    {
      id: 2,
      item: "No permitir replicar los experimentos.",
    },
    {
      id: 3,
      item: "Utilizar datos falsos y manipular los resultados a su favor o a favor de una empresa patrocinadora.",
    },
  ];

  const pub = [
    {
      id: 1,
      item: "Comprar artículos científicos para ser publicados en su nombre, o vender trabajos a otros",
    },
    {
      id: 2,
      item: "Presentar un original simultáneamente a dos revistas.",
    },
    {
      id: 3,
      item: "Publicar artículos con menos del 50% de novedad.",
    },
    {
      id: 4,
      item: "Presentar resultados positivos de forma intencionada cuando la investigación ha sido “patrocinada”.",
    },
    {
      id: 5,
      item: "Presentar datos o asuntos que no existen.",
    },
    {
      id: 6,
      item: "Falsificar datos reales o pruebas o datos deliberadamente distorsionados.",
    },
    {
      id: 7,
      item: "Publicación fragmentada (salami). ",
    },
    {
      id: 8,
      item: "No corregir interpretaciones erróneas de datos observados en artículos de otros.",
    },
    {
      id: 9,
      item: "La no retractación, en el caso de errores graves.",
    },
    {
      id: 10,
      item: "No Declarar los conflictos de interés que tiene en la investigación, ya sea financiación por parte de empresas o participación en acciones de organismos interesados en el tema.",
    },
    {
      id: 11,
      item: "Publicar en revistas depredadoras y contribuir a una mala difusión de la Ciencia.",
    },
  ];

  const general = [
    {
      id: 1,
      item: "Dejar sin sanción el plagio que descubre en sus alumnos.",
    },
    {
      id: 2,
      item: "No Reportar cualquier irregularidad dentro de la investigación que ponga en peligro los resultados de dicho trabajo.",
    },
    {
      id: 3,
      item: "No Conocer la ley sobre propiedad intelectual que rige en su país. Puesto que esta ley es relevante para su trabajo y se presume justa si procede de autoridad legítima (que es lo normal), el investigador debe conocerla a lo menos en general (Miranda Montecinos, 2013)",
    },
  ];

  return (
    <Row className="row-lib pt-2 mt-5">
      <Col className="">
        <div className="invDev mt-1">
          <div className="inner">
            <p className="title ms-3 !text-[#646567]">
              Conductas antiéticas en la investigación científica
            </p>
            <p className="ms-3">
              “Es la expectativa de que los profesores, los estudiantes, los
              investigadores y todos los miembros de la comunidad académica
              actúen con honestidad, confianza, equidad, respeto y
              responsabilidad”. Wikipedia
            </p>
            <Tabs defaultActiveKey="autoria" id="tabsInv" className="mb-3 mt-4">
              <Tab eventKey="autoria" title="Autoría" id="tab222">
                <ul className="features">
                  <br></br>

                  {autoria.map((aut) => (
                    <li key={aut.id}>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          />
                          <path
                            d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z"
                            opacity=".05"
                          />
                          <path
                            d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z"
                            opacity=".07"
                          />
                          <path
                            fill="#fff"
                            d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"
                          />
                          <path
                            fill="#fff"
                            d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"
                          />
                        </svg>
                      </span>
                      <span>{aut.item}</span>
                    </li>
                  ))}
                  <br></br>
                </ul>
              </Tab>

              <Tab
                eventKey="citas"
                className="navInv"
                title="Citas o crédito a otros autores"
              >
                <ul className="features">
                  <br></br>
                  {citas.map((cit) => (
                    <li key={cit.id}>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          />
                          <path
                            d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z"
                            opacity=".05"
                          />
                          <path
                            d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z"
                            opacity=".07"
                          />
                          <path
                            fill="#fff"
                            d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"
                          />
                          <path
                            fill="#fff"
                            d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"
                          />
                        </svg>
                      </span>
                      <span>{cit.item}</span>
                    </li>
                  ))}
                  <br></br>
                </ul>
              </Tab>

              <Tab eventKey="datos" title="Datos">
                <ul className="features">
                  <br></br>
                  {datos.map((dat) => (
                    <li key={dat.id}>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          />
                          <path
                            d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z"
                            opacity=".05"
                          />
                          <path
                            d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z"
                            opacity=".07"
                          />
                          <path
                            fill="#fff"
                            d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"
                          />
                          <path
                            fill="#fff"
                            d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"
                          />
                        </svg>
                      </span>
                      <span>{dat.item}</span>
                    </li>
                  ))}
                  <br></br>
                </ul>
              </Tab>

              <Tab eventKey="publicacion" title="Publicación científica">
                <ul className="features">
                  <br></br>
                  {pub.map((pu) => (
                    <li key={pu.id}>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          />
                          <path
                            d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z"
                            opacity=".05"
                          />
                          <path
                            d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z"
                            opacity=".07"
                          />
                          <path
                            fill="#fff"
                            d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"
                          />
                          <path
                            fill="#fff"
                            d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"
                          />
                        </svg>
                      </span>
                      <span>{pu.item}</span>
                    </li>
                  ))}
                  <br></br>
                </ul>
              </Tab>

              <Tab eventKey="plagio" title="Plagio">
                <ul className="features">
                  <Plagio />
                  <br></br>
                </ul>
              </Tab>

              <Tab eventKey="general" title="En general">
                <ul className="features">
                  <br></br>
                  {general.map((gen) => (
                    <li key={gen.id}>
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 48 48"
                          width="48px"
                          height="48px"
                        >
                          <path
                            fill="url(#wRKXFJsqHCxLE9yyOYHkza)"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                          />
                          <path
                            d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z"
                            opacity=".05"
                          />
                          <path
                            d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z"
                            opacity=".07"
                          />
                          <path
                            fill="#fff"
                            d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"
                          />
                          <path
                            fill="#fff"
                            d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"
                          />
                        </svg>
                      </span>
                      <span>{gen.item}</span>
                    </li>
                  ))}
                  <br></br>
                </ul>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default IAcademic;
