import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Investigation.css";
import ListGroup from "react-bootstrap/ListGroup";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const DataManagement = () => {
  const [enlaces, setEnlaces] = useState();

  const getApiDataEnlaces = async () => {
    const response = await axios
      .get(
        "https://sibucsc.cl/sibucsc/recursos/index/?categorias__categoria=26",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setEnlaces(response.data.results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getApiDataEnlaces();
  }, []);

  const renderTooltip = (descripcion) => (
    <Tooltip id="button-tooltip">{descripcion}</Tooltip>
  );

  const getImg = (img) => {
    try {
      return <img src={require(`../../assets/${img}`)} className="h-10" />;
    } catch (err) {
      return (
        <img
          src={require(`../../assets/images/resources/sin-foto.png`)}
          className="h-10"
        />
      );
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="cardCons mt-5">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">¿Qué son los datos de Investigación?</p>
              </div>
              <p className="infoP text-justify">
                Los datos de investigación son aquellos materiales generados o
                recolectados durante el transcurso de la realización de una
                investigación. Un informe financiado por el Higher Education
                Funding Council for England (HEFCE 2008) establece que “Los
                datos de investigación son la base de la evidencia sobre la que
                los investigadores académicos construyen su trabajo”.
              </p>
              <p className="infoP text-justify">
                Los datos de la investigación son hechos, observaciones o
                experiencias en que se basa el argumento, la teoría o la prueba.
                Los datos pueden ser numéricos, descriptivos o visuales. Los
                datos pueden ser en estado bruto o analizado, pueden ser
                experimentales u observacionales.
              </p>
            </div>

            <div className="invDev mt-3">
              <div className="inner">
                <p className="title">Los datos incluyen (tipos)</p>
                <ul className="features">
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Cuadernos de laboratorio, Cuadernos de campo.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Datos de Investigación primaria (incluidos los datos en
                      papel o en soporte informático).
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Cuestionarios, Cintas de audio Videos, Desarrollo de
                      modelos, Fotografías, Películas.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Comprobaciones y las respuestas de la prueba.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Las colecciones de datos para la investigación pueden
                      incluir diapositivas; diseños y muestra.
                    </span>
                  </li>
                </ul>
                <br></br>
              </div>
            </div>

            <div className="invDev mt-3">
              <div className="inner">
                <p className="title">Datos según el formato</p>
                <ul className="features">
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Textuales (Microsoft Wod, PDF, RTF, ODT, etc.) , Numéricos
                      (Excel, CSV, etc.), Multimedia (JPEG, MPEG, WAV, etc.),
                      Estructurados (XML, base de datos MySQL, etc.)
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      Código de software (Java, C, etc.) , Específicos de un
                      software (Mesh, 3D CAD, modelo estadístico, etc.),
                      Específicos de una disciplina (FITS en astronomía, CIF en
                      química, etc.) , Específicos de un instrumento (Olympus
                      Confocal Microscope Data Format, Carl Zeiss Digital
                      Microscopic Image Format
                    </span>
                  </li>
                </ul>
                <p className="text-justify">
                  En la información sobre la procedencia de los datos también se
                  podría incluir: el cómo, cuándo, dónde se recogió y con qué
                  (por ejemplo, instrumentos). El código de software utilizado
                  para generar, comentar o analizar los datos también pueden ser
                  considerados datos. Esta definición de datos de investigación
                  proviene de la Universidad de Melbourne y está recogida en el
                  documento de la FECYT (2012).
                </p>
                <br></br>
              </div>
            </div>

            <div className="invDev mt-3">
              <div className="inner">
                <p className="title">Tipos de datos de investigación</p>
                <ul className="features">
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      <strong>Datos primarios o sin procesar:</strong> Datos
                      originales que han sido recolectados pero aún no han sido
                      procesados o analizados. Algunos ejemplos son los
                      registros sonoros, observaciones, notas de campo o datos
                      de experimentos.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <strong>Datos procesados:</strong>{" "}
                    <span className="text-justify">
                      {" "}
                      Datos que han sido digitalizados, traducidos, transcritos,
                      limpiados, validados, verificados y/o anonimizados.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <strong>Datos analizados:</strong>{" "}
                    <span className="text-justify">
                      Modelos, gráficos, tablas, textos u otros, que han sido
                      creados a partir de los datos primarios y procesados, y
                      que se pretende sean de ayuda en el descubrimiento de
                      información útil, la presentación de conclusiones y la
                      toma de decisiones.
                    </span>
                  </li>
                </ul>
                <p>Fuente: Biblioguias; Biblioteca de la Cepal</p>
                <br></br>
              </div>
            </div>
          </Col>

          <Col>
            <div className="cardCons mt-5">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">Gestión de Datos de Investigación</p>
              </div>
              <p className="infoP text-justify">
                Los conjuntos o sets de datos de investigación constituyen la
                base de evidencia esencial de una publicación científica. La
                "gestión de datos de investigación" es el proceso activo de
                manejo y organización de los datos generados en una
                investigación, desde su ingreso al ciclo de investigación hasta
                la difusión y el archivo de resultados. Su objetivo es asegurar
                la verificación confiable de resultados y permite nuevos
                proyectos e investigaciones se basen en la información
                existente.
              </p>
              <p className="infoP text-justify">
                En este proceso investigación se involucran una serie de actores
                que participan en distintas etapas, entre ellos destacan:
              </p>
              <p className="invDevs">
                <ul className="features">
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      <strong>Investigadores/as:</strong> participan en la
                      recolección de datos en toda la investigación, además los
                      reutilizan y toman decisiones en torno a ellos.
                    </span>
                  </li>
                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      <strong>Bibliotecólogos/as o gestores de datos:</strong>{" "}
                      Gestionan los datos, etiquetándolos, organizándolos,
                      preservándolos y dando acceso a los mismos, los mismos
                      pueden asesorar también en temas de licenciamiento y
                      citación de conjuntos de datos.
                    </span>
                  </li>

                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      <strong>Data Curator:</strong> (Data Curation) consiste en
                      organizar y preservan los datos y materiales empleados en
                      la creación del conocimiento, una vez que los resultados
                      (artículo, monografía o ponencia) han sido publicados.
                    </span>
                  </li>

                  <li>
                    <span className="">
                      <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        class="iconify iconify--gis"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          d="M50 37.45c-6.89 0-12.55 5.66-12.55 12.549c0 6.89 5.66 12.55 12.55 12.55c6.655 0 12.112-5.294 12.48-11.862a3.5 3.5 0 0 0 .07-.688a3.5 3.5 0 0 0-.07-.691C62.11 42.74 56.653 37.45 50 37.45zm0 7c3.107 0 5.55 2.442 5.55 5.549s-2.443 5.55-5.55 5.55c-3.107 0-5.55-2.443-5.55-5.55c0-3.107 2.443-5.549 5.55-5.549z"
                          fill="#d12421"
                        ></path>
                      </svg>
                    </span>
                    <span className="text-justify">
                      <strong>Data stewardship:</strong> es relativamente nuevo,
                      y se refiere al desarrollo de actividades relacionadas con
                      la organización, gestión y custodia de los datos, y de los
                      metadatos que los acompañan.
                    </span>
                  </li>

                  <p className="infoP text-justify">
                    La gestión de los datos de manera eficaz a través de su
                    ciclo de vida es muy importante para el éxito del proyecto
                    de investigación. Una gestión de datos adecuada proporciona
                    un mayor impacto de la investigación optimizando la
                    visibilidad de los datos producidos, mejor accesibilidad a
                    los datos, asegurando la calidad e integridad de los mismos
                    durante su ciclo de vida, más transparencia para la
                    validación de los resultados de la investigación reflejados
                    en las publicaciones, la reutilización de los datos sin
                    necesidad de reproducir la investigación y el retorno de la
                    inversión pública cumpliendo con los requerimientos de los
                    organismos financiadores de la investigación
                  </p>
                </ul>
              </p>
            </div>

            <h3 className="text-[#646567] font-bold mt-4">
              Recursos educativos
            </h3>
            <ListGroup as="ol" numbered className="mt-3 invDev">
              {enlaces &&
                enlaces.map((enlace, i) => (
                  <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                    key={i}
                  >
                    <div className="ms-2 me-auto">
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(enlace.descripcion)}
                      >
                        <div className="fw-bold">{enlace.nombre}</div>
                      </OverlayTrigger>
                    </div>
                    {enlace.img.length > 0 && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(enlace.descripcion)}
                      >
                        <Badge
                          bg="bg-transparent"
                          className="badgeResources d-flex justify-content-center bg-[#D6625F]"
                        >
                          <a href={enlace.url} target="_blank">
                            {getImg(enlace.img)}
                          </a>
                        </Badge>
                      </OverlayTrigger>
                    )}
                  </ListGroup.Item>
                ))}{" "}
            </ListGroup>
          </Col>
        </Row>
      </Container>{" "}
      <br></br>
      <br></br>
    </>
  );
};
export default DataManagement;
