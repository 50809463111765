import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../css/Resources.css";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Mendeley from "../../assets/images/resources/mendeley-logo.png";
import Zotero from "../../assets/images/resources/zotero-logo.png";

const Manager = () => {
  return (
    <>
      <Container>
        <Row className="row-lib pt-2 mt-5">
          <Col lg={12} md={12} xs={12}>
            <div className="cardCons mt-3">
              <div className="header">
                <span className="iconPlagio">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </span>
                <p className="titleInv">
                  Ventajas de usar un Gestor bibliográfico
                </p>
              </div>
              <p className="infoP">
                Son programas que permiten crear referencias bibliográficas.
                Estas referencias se pueden utilizar para crear las citas y la
                bibliografía en los trabajos de investigación. Los datos se
                pueden introducir de manera manual o automática, a partir de
                búsquedas en catálogos de bibliotecas, bases de datos y revistas
                electrónicas.
              </p>
              <p className="infoP">
                Los GB son la mejor herramienta que nos permitirán mantener
                organizadas y a la mano las referencias bibliográficas y lograr
                citar documentos de manera correcta, minimizando o erradicando
                errores y optimizando el tiempo al citar la información usado
                durante nuestra investigación y permite dar formato a las citas
                y referencias bibliográficas con un solo click.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <img
              src={Mendeley}
              height={300}
              width={300}
              className="d-flex justify-content-center mt-5"
            />
            <ListGroup as="ol" numbered className="mt-3 invDev">
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.mendeley.com"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Sitio oficial Mendeley (inglés)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.mendeley.com/download-reference-manager/windows"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Descarga Software Mendeley Version Escritorio (inglés)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://community.mendeley.com/guides"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Ayuda en el uso de Mendeley (inglés)
                </Card.Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <img
              src={Zotero}
              height={300}
              width={300}
              className="d-flex justify-content-center mt-5"
            />
            <ListGroup as="ol" numbered className="mt-3 invDev">
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.zotero.org"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Sitio oficial Zotero (inglés)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.zotero.org/download/"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Descarga Software Zotero Version Escritorio (inglés)
                </Card.Link>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="d-flex align-items-start">
                <Card.Link
                  href="https://www.zotero.org/support/"
                  target="_blank"
                  className="text-[#D6092C] font-bold"
                >
                  &emsp;Ayuda en el uso de Zotero (inglés)
                </Card.Link>
              </ListGroup.Item>
            </ListGroup>
            <br></br>
          </Col>
        </Row>
        <br></br>
      </Container>
    </>
  );
};
export default Manager;
